/** subscription types */
export enum SubscriptionType {
  SP01 = 'SP01',
  SP06 = 'SP06',
  SP12 = 'SP12',
  CP12 = 'CP12',
  CP126 = 'CP126',
  SP03 = 'SP03',
  SP30 = 'SP30',
  GCSE = 'GCSE',
  /** estia labs platinum */
  ELP = 'ELP',
  /** estia labs gold */
  ELG = 'ELG',
  /** estia labs silver */
  ELS = 'ELS',
  /** estia labs non member */
  ELNM = 'ELNM',
  /** estia labs cash payment upfront */
  ELP13C = 'ELP13C',
  ELP12C = 'ELP12C',
  /** estia labs bronze */
  ELB = 'ELB'
}

/** Address as required by recurly */
export interface IAddress {
  city: string
  country: string
  phone?: string
  postalCode: string
  street1: string
  street2?: string
}

/** bacs billing information */
export interface IBacsBillingInfo {
  type: 'bacs'
  nameOnAccount: string
  accountNumber: string
  sortCode: string
  address: IAddress
}

export interface IRecurlyExistingPaymentMethod {
  type: 'recurly'
  billingId: string
}

export interface IPerson {
  firstName: string
  lastName: string
  email: string
  altEmail?: string
  address: IAddress
}

/** payment taken at time of account creation */
export interface IPayment {
  /** description of payment */
  description: string
  /** amount of payment */
  amount: number
}

/** Account creation interface only without any billing info */
export interface IAccountCreation extends IPerson {
  subscriberId: number
  username: string
}

export interface ExistingAccount {
  /** recurly account id */
  accountId: string
  /** subscriber id */
  subscriberId: number
  subscriptionType: SubscriptionType
}

export enum PaymentType {
  SINGLE = 'SINGLE',
  INSTALMENTS = 'INSTALMENTS',
  DEFERRED = 'DEFERRED'
}
/** initial payment through recurly */
export interface IAccountInitialPayment extends ExistingAccount {
  type: PaymentType
  /** token from recurly js */
  token: string
  /** three d secure token */
  threeDSecureToken?: string
  /** amount in cents total amount - for instalments this is divided by the number of instalments */
  amountInCents: number
  /** instalments if instalments type */
  numberOfInstalments?: number
  /** Date if deferred */
  deferredDate?: Date
  /** override how many months the initial payment is for */
  monthsInitialPayment?: number
}

export interface IAccountInitialPaymentResponse {
  /** receipt number to display to user */
  receiptNumber: string
  /** recurly billing info id */
  billingMethodId: string
  /** card type Visa / Mastercard */
  cardType: string
  /** expiry month */
  cardExpiryMonth: number
  /** Expiry year */
  cardExpiryYear: number
  /** first six digits */
  cardFirstSix: string
  /** last 4 digits */
  cardLastFour: string
}

/** information to create a recurly account for bacs billing */
export interface IAccount extends ExistingAccount {
  debitStart?: Date | string
  immediateNoticeProvided?: boolean
  /** user explicity asked to remote sign direct debit */
  remoteSignDirectDebit?: boolean
  /** billing info, either direct debit or existing payment method */
  billingInfo?: IBacsBillingInfo | IRecurlyExistingPaymentMethod
  /** is this is set then the @see billingInfo will be set to this person and a two accounts will be created (parent / child) */
  altPayer?: IPerson
  /** if initial payment not done on night set a date when it's supposed to be paid by @see payments must be set if this is used - even if just an empty array for no payment */
  /** the total of the initial payment amount left to pay */
  initialPaymentAmountOutstanding: number
  /** description for initial payment outstanding if not just "Manual" */
  initialPaymentAmountOutstandingReason?: string
  /** the first debit amount for initial monthly payments (used to be old initial amount) */
  initialMonthlyAmount: number
  initialPaymentAmountDueOn?: Date
  /** notes for initial invoice */
  initialPaymentCustomerNotes?: string
  /** list of payments made - will default to @see initialPaymentAmount required if @see initialPaymentAmountDueOn is set */
  payments?: IPayment[]
  /** bypass the sending of the initial mandate - the relevant information will be returned via the response if billing is setup @see IAccountResponse */
  bypassInitialMandate?: boolean
  /** number of months initial payment made defaults to 1 */
  monthsInitialPayment: number
  /** override the number of sessions by setting the custom variable on the subscription in recurly */
  numberOfSessionsOverride?: number
  /** expicity set the direct debit amount per month */
  directDebitAmount?: number
}

interface MandateInformation {
  accountNumber: string
  bankName: string
  firstDebitDate: string
  debitAmount: number
  nameOnAccount: string
  mandateId: string
  sortCode: string
  debitFrequency: 'monthly'
}

/** Account response */
export interface IAccountResponse {
  mandateSummary?: MandateInformation
}
